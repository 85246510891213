.w-flex .p-title {
  font-size: 12px;
}
.bold {
  font-weight: 700 !important;
}
.bolder {
  font-weight: 900 !important;
}
.main-nav .icon {
  width: 24px;
  height: 24px;
}

.main-nav ul li a > div {
  font-size: 25px;
  display: block;
  margin-bottom: 10px;
}
.c-pointer{
    cursor: pointer;
}